.free-plan {
  background: linear-gradient(105.74deg, #616161 8.19%, #3d3d3d 99.75%);
}
.free-plan-border {
  border: 1px solid;
  border-image-source: linear-gradient(
    100.24deg,
    #a3d1fc 0%,
    #8ac7ff 0.01%,
    #296cab 99.19%
  );
}
