body.dark {
  .slick-dots button:before {
    color: White !important;
  }
}
/* .slick-slide > div {
  height: auto !important;
} */
.slick-slide > div > div {
  outline: none !important;
}
.slick-dots li button:before {
  font-size: 10px;
}
