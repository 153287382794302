.App {
  padding: 30px 120px 70px 120px;
}

@media screen and (max-width: 460px) {
  .App {
    padding: 10px 15px 10px 15px;
  }
  .slick-prev {
    opacity: 0;
  }
  .slick-next {
    opacity: 0;
  }
}

@media (min-width: 461px) and (max-width: 767px) {
  .App {
    padding: 15px 30px 15px 30px;
  }
  .slick-prev {
    opacity: 0;
  }
  .slick-next {
    opacity: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .App {
    padding: 20px 60px 30px 60px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dark {
  background-color: #000000;
  color: #fff;
}
.light {
  background-color: #f6f7fa;
  color: #000000;
}
